<template>
  <v-card
    flat
  >
    <v-card-title>Dashboard</v-card-title>
  </v-card>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>

<style scoped>

</style>
